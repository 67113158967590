@import '../variables'

.dropdown-menu .btn
    font-size: $btn-font-size-lg
    line-height: $line-height-large
    font-weight: $font-weight-normal

    &.btn-outline-secondary
        color: $charcoal-700

.btn-close
    opacity: 0.7

button.btn
    font-weight: $font-weight-semibold
    border-width: 1px

.btn.disabled
    opacity: 1

.btn-xs
    font-size: $font-size-xs
    line-height: $line-height-xs
    padding: $btn-padding-y-xs $btn-padding-x-xs
    border-radius: $btn-border-radius-xs

// Primary

.btn-primary
    background-color: $green-700
    color: $white

.btn-primary:hover
    background-color: $green-600
    color: $white

.btn-primary:focus
    background-color: $green-700
    color: $white

.btn-primary:first-child:active, .btn-primary.active
    background-color: $green-900
    color: $white

.btn-primary:disabled
    background-color: $charcoal-50
    border-color: $charcoal-50
    color: $charcoal-300

// Info

.btn-info
    background-color: $teal-700
    border-color: $teal-700
    color: $white

.btn-info:hover
    background-color: $teal-500
    border-color: $teal-500
    color: $white

.btn-info:focus
    background-color: $teal-700
    border-color: $teal-700
    color: $white

.btn-info:first-child:active, .btn-info.active
    background-color: $teal-800
    border-color: $teal-800
    color: $white

.btn-info:disabled
    background-color: $charcoal-50
    border-color: $charcoal-50
    color: $charcoal-300

.btn-outline-info
    background-color: $white
    border-color: $charcoal-50
    color: $teal-700

.btn-outline-info:hover
    background-color: $charcoal-25
    border-color: $charcoal-100
    color: $teal-700

.btn-outline-info:focus
    background-color: $white
    border-color: $charcoal-200
    color: $teal-700

.btn-outline-info:first-child:active, .btn-outline-info.active
    background-color: $charcoal-50
    border-color: $charcoal-100
    color: $teal-700

.btn-outline-info:disabled
    background-color: $charcoal-25
    border-color: $charcoal-25
    color: $charcoal-700

// Primary

.btn-outline-primary
    background-color: $white
    border-color: $charcoal-50
    color: $green-700

.btn-outline-primary:hover
    background-color: $charcoal-25
    border-color: $charcoal-100
    color: $green-700

.btn-outline-primary:focus
    background-color: $white
    border-color: $charcoal-200
    color: $green-700

.btn-outline-primary:first-child:active,
.btn-outline-primary.active,
.btn-outline-primary:active,
:not(.btn-check) + .btn-outline-primary:active
    background-color: $charcoal-50
    border-color: $charcoal-100
    color: $green-900

.btn-check + .btn-outline-primary:hover
    background-color: $charcoal-50
    border-color: $charcoal-100
    color: $green-700

.btn-check:checked + .btn-outline-primary:hover
    background-color: $green-600
    border-color: $green-600
    color: $white

// Secondary

.btn-outline-secondary
    background-color: $white
    border-color: $charcoal-50
    color: $charcoal-900

.btn-outline-secondary:hover
    background-color: $charcoal-25
    border-color: $charcoal-100
    color: $charcoal-900

.btn-outline-secondary:focus
    background-color: $white
    border-color: $charcoal-200
    color: $charcoal-900

.btn-outline-secondary:first-child:active,
:not(.btn-check) + .btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary:active
    background-color: $charcoal-50
    border-color: $charcoal-100
    color: $charcoal-900

.btn-outline-secondary:disabled, .btn-outline-secondary.disabled
    background-color: $charcoal-25
    border-color: $charcoal-25
    color: $charcoal-700

// Danger

.btn-outline-danger
    background-color: $white
    border-color: $charcoal-50
    color: $red-700

.btn-outline-danger:hover
    background-color: $charcoal-25
    border-color: $charcoal-100
    color: $red-700

.btn-outline-danger:focus
    background-color: $white
    border-color: $red-200
    color: $red-700

.btn-outline-danger:first-child:active,
.btn-outline-danger.active,
.btn-outline-danger:active,
.btn-check:checked + .btn-outline-danger,
:not(.btn-check) + .btn-outline-danger:active
    background-color: $charcoal-50
    border-color: $charcoal-50
    color: $red-900

.btn-outline-danger:first-child:active, .btn-outline-danger.active
    background-color: $charcoal-50
    border-color: $charcoal-50
    color: $red-900

.btn-outline-danger:disabled
    background-color: $charcoal-50
    border-color: $charcoal-50
    color: $charcoal-300
