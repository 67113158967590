@import 'colours'

$primary: rgb(21, 136, 72)
$body-color: $charcoal-900
$secondary: $charcoal-700
$body-secondary-color: $charcoal-700
$dark-bg-subtle: $charcoal-50
$text-muted: $charcoal-600
$light: $charcoal-25
$light-bg-subtle: $charcoal-25
$light-bg-subtle-dark: $charcoal-50
$light-text-emphasis: $charcoal-800
$light-text-emphasis-dark: $charcoal-900
$info-border-subtle: $blue-100
$info-bg-subtle: $blue-25
$info-bg-subtle-dark: $blue-100
$info-text-emphasis: $blue-800
$info-text-emphasis-dark: $blue-900
$info: $blue-800
$warning: $orange-800
$danger: rgb(218, 58, 62)
$grey: rgb(158, 11, 162)
$black: rgb(47, 47, 65)
$white_offset: rgb(246, 247, 255)
$dark: $charcoal-900

$highlight_colour: rgb(32, 186, 57) !important


$font-size-xs: 0.815rem
$font-size-small: 0.875rem
$font-size-sm: 0.875rem
$font-size-md: 1rem
$font-size-large: 1.125rem
$font-size-lg: 1.25rem
$font-size-display-small: 1.5rem

$font-sizes: (1: $font-size-xs, 2: $font-size-small, 3: $font-size-sm, 4: $font-size-md, 5: $font-size-large, 6: $font-size-lg)

$line-height-xs: 1.125rem
$line-height-small: 1.25rem
$line-height-md: 1.5rem
$line-height-large: 1.75rem
$line-height-lg: 1.75rem
$line-height-display-small: 2rem

$border-color: $charcoal-50
$border-radius-xs: 0.25rem
$border-radius-sm: 0.375rem
$border-radius-md: 0.5rem
$border-radius-lg: 0.625rem
$border-radius-xl: 0.75rem
$border-radius-xxl: 1rem
$border-radius: $border-radius-xl

$badge-border-radius: $border-radius-sm
$badge-font-size: $font-size-xs
$badge-font-weight: 600
$badge-padding-x: 7px
$badge-padding-y: 0.25rem

$btn-border-width: 1px
$btn-border-radius-xs: 0.5rem
$btn-border-radius-sm: $border-radius
$btn-border-radius: $border-radius
$btn-border-radius-lg: $border-radius
$btn-line-height: 1.5rem
$btn-padding-x-xs: 8px - $btn-border-width
$btn-padding-y-xs: 5px - $btn-border-width
$btn-padding-x-sm: 12px - $btn-border-width
$btn-padding-y-sm: 8px - $btn-border-width
$btn-padding-x: 16px - $btn-border-width
$btn-padding-y: 8px - $btn-border-width
$btn-padding-x-lg: 24px - $btn-border-width
$btn-padding-y-lg: 14px - $btn-border-width
$btn-font-size-sm: 1rem
$btn-font-size: 1rem
$btn-font-size-lg: 1.125rem

$card-border-color: $charcoal-50

$modal-header-padding: 1rem 1.5rem
$modal-content-border-radius: $border-radius-xxl
$modal-footer-bg: $charcoal-25
$modal-footer-border-width: 0
$modal-lg: 582px
$modal-dialog-margin: 1rem

$input-border-color: $charcoal-50
$input-border-radius-sm: 0.625rem
$input-border-radius: 0.625rem
$input-border-radius-lg: 0.625rem
$input-font-weight: 600
$input-font-size-lg: 1rem
$input-padding-y: 8px - $btn-border-width
$input-padding-y-lg: 14px - $btn-border-width
$input-placeholder-color: $charcoal-500

$list-group-color: $charcoal-900

$spinner-border-width: 2px

$table-hover-bg: $charcoal-25
$table-th-font-weight: 600

$toast-header-color: $black
$toast-color: $charcoal-900
$toast-border-radius: $border-radius
$toast-border-width: 2px
$toast-padding-x: 1rem
$toast-padding-y: 0.625rem

$nav-pills-border-radius: $border-radius-lg
$nav-pills-link-active-color: $charcoal-800
$nav-pills-link-active-bg: $charcoal-50
$nav-link-padding-x: 0.75rem
$nav-link-padding-y: 0.5rem
$nav-link-font-weight: 600
$nav-link-color: $charcoal-700
$nav-link-hover-color: $charcoal-800

$h4-font-size: 1.25rem
$h4-line-height: 1.75rem
$headings-color: $charcoal-900

@import 'bootstrap/scss/functions'
@import 'bootstrap/scss/variables'
